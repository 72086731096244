import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledProtocolPage = styled.div<StyledProcotolPageProps>`
  .protocol-hero {
    @media (min-width: 768px) {
      display: flex;

      &--content {
        width: ${p => (p.hasHeroImage ? '50%' : '100%')};
      }

      &--image {
        width: 50%;
      }
    }

    &--image {
      img {
        transform: scale(1.2);
        transition: transform;
        transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        transition-duration: 2s;
      }

      &.visible img {
        transform: scale(1);
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: ${pxtorem(42)};
      min-height: ${p => (p.hasHeroImage ? '0' : '500px')};
    }

    &--label,
    &--author-name {
      font-size: ${pxtorem(12)};
      line-height: 1.1875;
      font-weight: 500;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: ${pxtorem(16)};
      }
    }

    &--title {
      font-family: var(--secondaryFont);
      font-size: ${pxtorem(34)};
      line-height: 1.1154;
      letter-spacing: -1px;
      margin: ${pxtorem(8)} 0;

      @media (min-width: 768px) {
        font-size: ${pxtorem(52)};
        margin: ${pxtorem(18)} 0 ${pxtorem(28)};
      }
    }

    &--author-avatar {
      border-radius: 100%;
      overflow: hidden;
      max-width: 47px;
      margin: ${pxtorem(20)} auto ${pxtorem(10)};

      @media (min-width: 768px) {
        max-width: 72px;
      }
    }

    &--author-location {
      font-size: ${pxtorem(12)};
    }
  }
`;

interface StyledProcotolPageProps {
  hasHeroImage: boolean;
}
