import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { Protocol } from '../types/types';

import { ProtocolPage } from '~/components/ProtocolPage/ProtocolPage';

const ProtocolTemplate: React.FC<ProtocolPageProps> = ({ data }) => {
  const { protocol } = data;

  return (
    <Layout floatingHeader>
      <Metadata
        title={protocol.title}
        description={protocol.shortDescription}
      />
      <ProtocolPage protocol={protocol} />
    </Layout>
  );
};

export const query = graphql`
  query ProtocolQuery($slug: String, $locale: String) {
    protocol: contentfulGuidedProtocol(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ProtocolPageFragment
    }
  }
`;

interface ProtocolPageProps {
  data: {
    protocol: Protocol;
  };
}

export default ProtocolTemplate;
