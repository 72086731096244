import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Section } from '~/styles/Section.styled';
import { Wrapper } from '~/styles/Wrapper.styled';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';

import { StyledProtocolPage } from './ProtocolPage.styled';
import {
  PageSection,
  ComponentMapExtraData,
} from '~/components/PageSection/PageSection';

import { Protocol } from '~/types/types';

const protocolSectionsDefaultData: ComponentMapExtraData = {};

export const ProtocolPage: React.FC<ProtocolPageProps> = ({ protocol }) => {
  const { heroLabel, title, heroImage, author, sections } = protocol;
  const [isHeroImageVisible, setIsHeroImageVisible] = useState(false);

  return (
    <StyledProtocolPage hasHeroImage={!!heroImage}>
      <Section bg="lime">
        <Wrapper width="full" rounded={false} gutter={false}>
          <div className="protocol-hero">
            <div className="protocol-hero--content">
              <ShowOnScroll>
                <span className="protocol-hero--label">{heroLabel}</span>
              </ShowOnScroll>
              <ShowOnScroll delay={50}>
                <h1 className="protocol-hero--title">{title}</h1>
              </ShowOnScroll>
              {author && author.name && <ShowOnScroll delay={100}>
                <span className="protocol-hero--author-name">
                  Curated By {author.name}
                </span>
              </ShowOnScroll>}
              {author?.image?.gatsbyImageData && <ShowOnScroll delay={150}>
                <div className="protocol-hero--author-avatar">
                  <GatsbyImage
                    image={author.image.gatsbyImageData}
                    alt={author.image.title}
                    objectFit="cover"
                  />
                </div>
                <div className="protocol-hero--author-location">
                  {author.location}
                </div>
              </ShowOnScroll>
      }
            </div>
            {heroImage && (
              <ShowOnScroll
                passive
                delay={100}
                onVisible={() => setIsHeroImageVisible(true)}
                onNoLongerVisible={() => setIsHeroImageVisible(false)}
                className={`protocol-hero--image ${
                  isHeroImageVisible ? 'visible' : ''
                }`}
              >
                <GatsbyImage
                  image={heroImage.gatsbyImageData}
                  alt={heroImage.title}
                  objectFit="cover"
                />
              </ShowOnScroll>
            )}
          </div>
        </Wrapper>
      </Section>

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData =
            protocolSectionsDefaultData[section.sectionType.slug] || {};

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledProtocolPage>
  );
};

interface ProtocolPageProps {
  protocol: Protocol;
}
